<template>
	<div class="signup-form-component" :class="$responsive">
		<form-header image="zionesport-wallpaper-app-std-v2-small.png">
			Estás a unos pasos de llevar tu Liga a otro nivel
		</form-header>
		<div class="signup-form-component__body">
			<h1 class="title" v-markdown:title>
				Alta de *Cliente*
			</h1>
			<p class="subtitle">
				Completa la siguiente información para el alta de tu servicio.
			</p>
			<form class="signup-form" method="post" action="#" @submit.prevent="submitForm">
				<section class="signup-section" v-for="(section, sectionIndex) in form" :key="sectionIndex">
					<h3 class="signup-section__title" v-if="section.title">
						{{ section.title }}
					</h3>
					<div class="signup-section__optional" v-if="section.optional">
						<input type="checkbox" v-model="section.optional.value">
						<span>{{ section.optional.description }}</span>
					</div>
					<p class="signup-section__description" v-if="section.description">
						{{ section.description }}
					</p>
					<div v-show="showSection(section)">
						<div v-if="section.fields">
							<div v-for="(field, key, index) in section.fields" :key="key">
								<form-field :index="index" :field="field" v-model="field.value" />
							</div>
						</div>
						<div v-else-if="section.list">
							<input-list :list="section.list" v-model="section.list.data"></input-list>
						</div>
					</div>
					<div class="signup-comments" v-if="section.comment">
						{{ section.comment }}
					</div>
					<div class="signup-section__error-message" v-if="section.errorMessage">
						{{ section.errorMessage[0] }}
					</div>
				</section>
				<base-button class="signup-form__submit">
					Enviar Información
				</base-button>
				<div class="signup__error-msg" v-show="!errorFree">
					Revisa la información y completa los campos necesarios para el alta de cliente.
				</div>
				<re-captcha ref="recaptcha" @verify="doSubmit"></re-captcha>
			</form>
		</div>	
	</div>
	<modal-processing v-if="isSending">
		Enviando tu información..
	</modal-processing>
</template>

<script>
const axios = require('axios');
import reCaptcha from "@/components/ReCaptchaV2.vue";
import modalProcessing from "@/components/ModalProcessing.vue";
import baseButton from "@/components/BaseButton.vue";
import formField from "@/components/FormField";
import useForm from "@/forms.js";
import inputList from "@/components/InputList.vue";
import formHeader from "@/components/FormHeader.vue";

export default {
	name: "SignupForm",
	components: {
		reCaptcha,
		modalProcessing,
		baseButton,
		formField,
		inputList,
		formHeader
	},
	emits: ['sent'], // new on Vue3
	setup() {
		const { checkSections } = useForm();
		return { checkSections };
	},
	data() {
		return {
			form: [
				{
					title: "Datos de la liga",
					description: "Escribe los datos generales de la liga.",
					fields: {
						leagueName: {
							value: "",
							type: "text",
							label: "Nombre de la Liga",
							required: true,
							errorMessage: []
						},
						primaryContact: {
							value: "",
							type: "text",
							label: "Contacto Primario",
							required: true,
							errorMessage: [],
							helpText: "Nombre del usuario principal, dueño o socio de la liga."
						},
						sports: {
							value: [], // array of checked items
							type: "checkbox",
							label: "Deportes",
							items: ['Fútbol', 'Tocho', 'Basquetbol', 'Voleibol', 'Beisbol', 'Raqueta', 'Otro'],
							required: true,
							errorMessage: []
						}
					}	
				},
				{
					title: "Información para contacto e informes",
					description: "Escribe la información de contacto de la liga; esta información es pública para que los interesados puedan solicitar información sobre tus torneos.",
					fields: {
						phone: {
							value: "",
							type: "text",
							label: "Teléfono(s)",
							required: true,
							errorMessage: []
						},
						whatsapp: {
							value: "",
							type: "text",
							label: "Whatsapp",
							required: false,
							errorMessage: [],
						},
						email: {
							value: "",
							type: "email",
							label: "Correo electrónico oficial",
							required: false,
							errorMessage: []
						},
					}
				},
				{
					optional: {
						description: "Incluir datos de facturación",
						value: false
					},
					description: "Información y datos fiscales para la emisión de tus facturas y complementos de pago.",
					fields: {
						rfc: {
							value: "",
							type: "text",
							label: "RFC",
							required: true,
							errorMessage: []
						},
						razonSocial: {
							value: "",
							type: "text",
							label: "Razón Social",
							required: true,
							errorMessage: []
						},
						regimenFiscal: {
							value: "",
							type: "select",
							label: "Régimen fiscal",
							values: [null, '601	General de Ley Personas Morales', '603	Personas Morales con Fines no Lucrativos', '605	Sueldos y Salarios e Ingresos Asimilados a Salarios', '606	Arrendamiento', '611	Ingresos por Dividendos (socios y accionistas)', '612	Personas Físicas con Actividades Empresariales y Profesionales', '616	Sin obligaciones fiscales', '621	Incorporación Fiscal', '626	Régimen Simplificado de Confianza'],
							required: true,
							errorMessage: [],
							helpText: "Requerido para CFDI 4.0"
						},
						postalCode: {
							value: "",
							type: "text",
							label: "Código postal",
							required: true,
							errorMessage: [],
							helpText: "Requerido para CFDI 4.0"
						},
						address1: {
							value: "",
							type: "text",
							label: "Dirección",
							required: true,
							errorMessage: [],
							helpText: "Calle, Número y Colonia"
						},
						address2: {
							value: "",
							type: "text",
							label: "Ciudad / Estado",
							required: true,
							errorMessage: [],
							helpText: "Ciudad y Estado"
						},
						email: {
							value: "",
							type: "text",
							label: "Correo(s) para recibir facturas",
							required: true,
							errorMessage: [],
							helpText: "Incluye uno o más correos separados por comas."
						}
					}
				},
				{
					optional: {
						description: "Redes Sociales",
						value: false
					},
					description: "Copia y pega las URLs de tus redes sociales.",
					fields: {
						facebook: {
							value: "",
							type: "text",
							label: "Facebook",
							required: false,
							errorMessage: []
						},
						twitter: {
							value: "",
							type: "text",
							label: "Twitter",
							required: false,
							errorMessage: []
						},
						instragram: {
							value: "",
							type: "text",
							label: "Instragram",
							required: false,
							errorMessage: []
						},
						tiktok: {
							value: "",
							type: "text",
							label: "Tiktok",
							required: false,
							errorMessage: []
						},
						hashtags: {
							value: "",
							type: "text",
							label: "#hashtags",
							required: false,
							errorMessage: []
						},
						other: {
							value: "",
							type: "text",
							label: "Otro",
							required: false,
							errorMessage: []
						}
					}
				},
				{
					title: "Usuarios",
					description: "Añade nombre y correo electrónico de todos los usuarios que tendrán acceso a la consola de administración de los torneos.",
					list: {
						name: "Usuarios",
						model: { name: '', email: '' },
						labels: ["Nombre y Apellido", "Correo electrónico"],
						addLabel: "+ añadir usuario",
						data: []
					},
					errorMessage: []
				},
				{
					fields: {
						clientComments: {
							value: "",
							type: "textarea",
							label: "Comentarios",
							rows: 2,
							required: false,
							errorMessage: []
						},
					}
				}
			],
			sent: false,
			isSending: false,
			errorFree: true
		}
	},
	computed: {
		leagueName() {
			return this.form[0].fields.leagueName.value;
		}
	},
	methods: {
		showSection(section) {
			return !section.optional || section.optional.value;
		},
		doSubmit(token) {
			const getFields = fields => Object.entries(fields).reduce((acc, el) => ({...acc, [el[0]]: el[1].value }), {});
			const signupData = this.form.reduce((obj, section) => ({...obj, ...getFields(section.fields || {})}), {});

			const configData = this.form.filter(section => section.list)
				.map(section => ({ name: section.list.name, data: section.list.data }));

			this.isSending = true;
			axios.post('/api/signup', { data: signupData, params: configData, recaptchaToken: token })
				.then(response => {
					console.log(response);
					this.sent = true;
				})
				.catch(error => console.log(error))
				.finally(() => {
					this.isSending = false;
					this.$emit('sent', this.leagueName);
				});	
		},
		submitForm() {
			let checkSections = this.checkSections(this.form);
			let validateUsers = this.validateUsers(); 
			this.errorFree = checkSections && validateUsers;
			if (this.errorFree) { 
				this.$refs.recaptcha.execute();
			}
		},
		validateUsers() {
			const users =  this.inputListObject('Usuarios')
			users.errorMessage = !users.list.data.filter(user => (user.name && user.email)).length
				? ["Añade por lo menos un usuario"]
				: [];
			return !users.errorMessage.length;
		},
		inputListObject(listName) {
			return this.form.filter(section => section.list?.name == listName)[0];
		}
	}
}
</script>

<style scoped>
.signup-form-component {
	margin: 0 auto;
	background-color: #ffffff;
	border-radius: 1rem;
	overflow: hidden;
}

.signup-form-component__body {
	padding: 2rem 3rem;
}

.mobile .signup-form-component__body {
	padding: 2rem 1rem;
}

.signup-form-component.desktop {
	max-width: 80%;	
}

.desktop .subtitle {
	margin: 2rem auto;
	max-width: 80%;
}

.signup-form {
	font-family: 'open sans', helvetica, sans-serif;
}

.signup-form__submit {
	margin: 2.5rem auto 2rem;	
}

.signup-section {
	margin-bottom: 3.75rem;
}

.mobile .signup-section {
	margin-bottom: 4.75rem;	
}

.signup-section__title {
	font-size: 1rem;
	text-align: left;
	margin: 0 0 .75rem;
}

.signup-section__description {
	font-size: .8125rem;
	text-align: left;
	color: var(--secondary-color);
	line-height: 1.66em;
	margin: 0 0 1.75rem;
}

.signup-section__optional {
	text-align: left;
	font-size: .8125rem;
	margin-bottom: .75rem;
	font-weight: 600;
	display: flex;
	align-items: center;
}

.signup-section__optional > span {
	margin-left: .5em;
}

.signup-comments {
	text-align: left;
	font-size: .9375rem;
	font-size: .875rem;
	border-bottom: 1px solid #efefef;
	padding-bottom: 1.5rem;
	margin-top: 2rem;
	line-height: 1.66em;
}

.signup__error-msg {
	color: var(--error-color);
	margin-bottom: 2rem;
}

.signup-section__error-message {
	color: var(--error-color);
	text-align: left;
	font-size: .875rem;
	margin-top: 1em;
}
</style>