<template>
	<div class="signup">
		<div class="page-section" :class="$responsive">
			<div v-if="!sent">
				<signup-form @sent="sentDone"></signup-form>
			</div>
			<div v-else>
				<success-message :contactName="leagueName" :marketing="marketing">
					hemos recibido la información para el alta de cliente.
				</success-message>
			</div>
		</div>
	</div>
</template>

<script>
	import content from "@/content.json";
	import signupForm from "@/components/SignupForm.vue";
	import successMessage from "@/components/SuccessMessage.vue";
	
	export default {
		name: "Signup",
		components: {
			signupForm,
			successMessage
		},
		data() {
			return {
				sent: false,
				leagueName: "",
				marketing: content.marketing.signupAd
			}
		},
		methods: {
			sentDone(leagueName) {
				this.leagueName = leagueName;
				this.sent = true;
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
			}
		}
	}
</script>

<style scoped>
.signup .view-header {
	min-height: 0;
	padding-top: 0;
}
.page-section.mobile {
	padding: 1rem;
}
</style>